import { almagro, pilares, socovesa, empresas } from './filialesNames';

export const white = '#ffffff';
export const black = '#000000';
export const grey = '#BEBFC5';
export const pilaresGrey = '#303446';
export const socovesaPrimary = '#53308f';
export const almagroPrimary = '#E52713';
export const almagroSecondary = '#F8F8F8';
export const pilaresPrimary = '#ff6900';
export const pilaresSecondary = white;
export const currentColor = 'currentColor';
export const empresasTextPrimary = '#003a63';
export const empresasPrimary = '#016f98';
export const empresasSecondary = white
export const quaternaryDefault = '#8e8f96';

export const colorConfig = {
  [almagro]: {
    primary: almagroPrimary,
    secondary: almagroSecondary,
    terciary: '#4D4D4D',
    quaternary: quaternaryDefault,
    headerTexts: '#4D4D4D',
    icons: '#4D4D4D',
    fondo: 'antiquewhite',
    textResalt: almagroSecondary
  },
  [socovesa]: {
    primary: socovesaPrimary,
    secondary: '#eff3f8',
    terciary: black,
    headerTexts: '#53308f',
    quaternary: quaternaryDefault,
    icons: black,
  },
  [pilares]: {
    primary: pilaresPrimary,
    secondary: pilaresSecondary,
    terciary: pilaresGrey,
    quaternary: quaternaryDefault,
    headerTexts: black,
    icons: black,
  },
  [empresas]: {
    primary: empresasPrimary,
    secondary: empresasSecondary,
    terciary: black,
    headerTexts: black,
  },
};
