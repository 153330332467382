import React from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';
import MessageNotificationIcon from '../Icons/MessageNotificationIcon';
import RightArrowIcon from '../Icons/RightArrowIcon';

import { primary, secondary } from '../../constants/colorTypes';
import { white } from '../../constants/colors';
import { almagro } from '../../constants/filialesNames';

import './NotificationList.scss';

const NotificationList = ({
  subsidiary,
  notificationList: { numero_mensajes: messageNumber = 0 } = {},
  onClick,
}) => {
  return (
    <div className={`NotificationList NotificationList--${subsidiary}`}>
      <Button
        onClick={onClick}
        color={subsidiary === almagro ? secondary : primary}
        variant="text"
        className="NotificationList__buttonWrapper"
      >
        <div className="NotificationList__container">
          <div
            className={`NotificationList__container__iconWrapper NotificationList__container__iconWrapper--${subsidiary}`}
          >
            <MessageNotificationIcon
              color={white}
              className="NotificationList__container__iconWrapper__icon"
            />
          </div>
          <span className={`NotificationList__container__notification--${subsidiary}`}>
            {messageNumber > 0 && `Tienes${' '}`}
            <b>
              {messageNumber > 0
                ? `${messageNumber} mensaje${messageNumber > 1 ? 's' : ''}`
                : 'No tienes mensajes'}
            </b>{' '}
            nuevo{`${messageNumber > 1 || messageNumber === 0 ? 's' : ''}`}
          </span>

          <RightArrowIcon className="NotificationList__container__url" />
        </div>
      </Button>
    </div>
  );
};

NotificationList.propTypes = {
  subsidiary: PropTypes.string,
  notificationList: PropTypes.object,
  onClick: PropTypes.func,
};

export default NotificationList;
