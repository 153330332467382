import { useEffect, useState } from 'react';
import { getClientList } from '../services/clientService';
import { checkIfRutHasDash, formatRut } from '../utils/utils';

export const useHandlePagination = () => {
  const [pagination, setPagination] = useState({ page: 1, limit: 10000 });
  const addMoreRows = () => {
    setPagination({ page: 1, limit: pagination.limit + 10000 });
  };
  return { pagination, addMoreRows };
};

export const updateClientList = (
  executiveRut,
  setClientList,
  activeSubsidiary,
  filterByText = '',
  page,
  limit,
  filter,
) => {
  getClientList(
    // executiveRut.substring(0, executiveRut.length - 2),
    checkIfRutHasDash(executiveRut),
    activeSubsidiary,
    filterByText,
    page,
    limit,
    filter,
    ({ data }) => {
      const clientList =
        data.data && data.data.length > 0
          ? data.data.map(({ propiedades, nombres, apellidos, id_Cliente: idClient, ...rest }) => ({
              fullName: `${nombres} ${apellidos}`,
              nombres,
              apellidos,
              rut: formatRut(idClient),
              id_Cliente: idClient,
              projectName: propiedades[0] && propiedades[0].nombreProyecto,
              state: 'activo',
              idProject: propiedades[0] && propiedades[0].id_Proyecto,
              properties: [...propiedades],
              propertyCount: propiedades.length,
              ...rest,
            }))
          : [];
      setClientList(clientList);
    },
    err => {
      console.error(err);
    },
  );
};

const useClientList = (executiveRut, pagination, activeSubsidiary, letterFilter, searchFilter) => {
  const [clientList, setClientList] = useState([]);
  useEffect(() => {
    if (executiveRut && activeSubsidiary && searchFilter) {
      const { page, limit } = pagination;
      updateClientList(
        executiveRut,
        setClientList,
        activeSubsidiary,
        letterFilter,
        page,
        limit,
        searchFilter,
      );
    }
  }, [activeSubsidiary, letterFilter, pagination, executiveRut, searchFilter]);

  return {
    clientList,
    setClientList,
  };
};

export default useClientList;
