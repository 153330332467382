import React, { memo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TablePagination from '@material-ui/core/TablePagination';

// Components
import ProjectItem from './ProjectTableItem';
import SubsidiaryTabs from '../SubsidiaryTabs';
import Searchbar from '../Searchbar';
import AlphabeticalFilter from '../AlphabetFilter';
import Button from '../ButtonTypes';
import ArrowDownCircle from '../Icons/ArrowDownCircle';

import { almagroCode } from '../../constants/filialesNames';

import './ProjectTable.scss';
import SortIcon from '../../components/Icons/SortIcon';

const formatList = (columns, rawList = []) =>
  rawList.reduce(
    (acom, item) => [
      ...acom,
      {
        ...columns.reduce((acc, { key, defaultValue, circle = false, id = false, label }) => {
          const obj = {
            ...acc,
            [id ? 'id' : key]: { value: item[key] || defaultValue, circle, label },
          };
          if ((obj[key] !== undefined && obj[key].value !== undefined) || id) return obj;
          delete obj[key];
          return obj;
        }, {}),
      },
    ],
    [],
  );

const ProjectTable = ({
  itemList = [],
  columns: columnList = [],
  subsidiary,
  activeSubsidiary = localStorage.getItem('filial') || almagroCode,
  subsidiaryOptions,
  onChangeSubsidiary,
  onChangeSearch,
  onLetterChangeCallback,
  onClientStatusChange,
  className,
  onItemClick,
  messageEmpty,
  onChangeOrder,
}) => {
  const [textFilter, setFilterText] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleSearchChange = useCallback(
    (newValue) => {
      if (onChangeSearch) onChangeSearch(newValue);
      setFilterText(newValue);
    },
    [onChangeSearch]
  );

  const handleTabChange = useCallback(
    (event, newValue) => {
      localStorage.setItem('filial', newValue);
      if (onChangeSubsidiary) onChangeSubsidiary(newValue);
      if (onChangeSearch) onChangeSearch(textFilter);
    },
    [onChangeSubsidiary, textFilter]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const useHandleList = (propList = [], propColumns = []) => {
    const [list, setList] = useState([]);
    const [columns, setColumns] = useState([]);
    const updateList = (newList) => {
      if (!R.equals(newList, list)) setList([...newList]);
    };
    useEffect(() => {
      if (columns.length > 0 && propList.length > 0) {
        const newList = formatList(columns, propList);
        updateList([...formatList(columns, propList)]);
      } else {
        updateList([]);
      }
    }, [propList, columns]);
    useEffect(() => {
      if (propColumns.length > 0) {
        setColumns(propColumns.filter(({ label = '', id }) => (label && label !== '') || id));
      }
    }, [propColumns]);
    return { list, columns };
  };

  const { list, columns } = useHandleList(itemList, columnList);
  const paginatedList = list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const mobile = useMediaQuery('(max-width: 768px)');

  return (
    <div className={`ProjectTable ${className}`}>
      <div className="ProjectTable__header">
        {activeSubsidiary && onChangeSubsidiary && (
          <SubsidiaryTabs
            value={activeSubsidiary}
            options={subsidiaryOptions}
            onChange={handleTabChange}
          />
        )}
      </div>
      {window.location.href.split('/').pop() != 'Home' && 
      <div className="EnterpriseMessageList__searchbar">
        <Searchbar
            helperMessage="¿Estás buscando un cliente en particular?"
            onChange={setFilterText}
            onSubmit={handleSearchChange}
            value={textFilter}
          />
      </div>}
      <div className="ProjectTable__subheader">
        {onLetterChangeCallback && (
          <AlphabeticalFilter
            subsidiary={subsidiary}
            onLetterChangeCallback={onLetterChangeCallback}
          />
        )}
      </div>
      {!mobile ? (
        <div className="ProjectTable__desktop">
          {list.length > 0 ? (
            <div className="ProjectTable__body">
              <table className="ProjectTable__body__table">
                <thead>
                  <tr className="ProjectTable__body__table__header">
                    {columns.map(({ label, key, circle, id, ordered, keyOrder }) =>
                      !id ? (
                        <th key={key}>
                          <span
                            onClick={() => onChangeOrder && onChangeOrder(keyOrder)}
                            style={{ cursor: 'pointer' }}
                          >
                            {label}
                            {ordered !== undefined && <SortIcon />}
                          </span>
                        </th>
                      ) : null
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedList.map(({ id: { value } = {}, ...item }, index) => (
                    <ProjectItem
                      key={value}
                      {...item}
                      onClick={onItemClick}
                      id={value}
                      onClientStatusChange={onClientStatusChange}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="ProjectTable__body__emptyMessage">{messageEmpty}</div>
          )}
        </div>
      ) : (
        <div className="ProjectTable__mobile">
          {paginatedList.map(({ id: { value } = {}, ...item }) => (
            <ProjectItem
              key={value}
              {...item}
              onClick={onItemClick}
              id={value}
              mobile={mobile}
              onClientStatusChange={onClientStatusChange}
            />
          ))}
        </div>
      )}
     <TablePagination
      component="div"
      count={list.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Filas por página:"
      labelDisplayedRows={({ from, to, count }) =>
        `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`
      }
    />
    </div>
  );
};

ProjectTable.propTypes = {
  itemList: PropTypes.array,
  columns: PropTypes.array,
  subsidiary: PropTypes.string,
  activeSubsidiary: PropTypes.string,
  subsidiaryOptions: PropTypes.array,
  onChangeSubsidiary: PropTypes.func,
  onChangeSearch: PropTypes.func,
  onLetterChangeCallback: PropTypes.func,
  onClientStatusChange: PropTypes.func,
  className: PropTypes.string,
  onItemClick: PropTypes.func,
  messageEmpty: PropTypes.string,
  onChangeOrder: PropTypes.func,
};

export default memo(ProjectTable);
