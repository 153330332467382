import React, { useContext, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components
import ProjectTable from '../../components/ProjectTable';
import NotificationList from '../../components/NotificationList';
import ExecutiveSelector from '../../components/ExecutiveSelector';

// Provider
import { EnterpriseContext } from '../../providers/EnterpriseProvider';

import './EnterpriseHome.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import useGetExecutiveProjects from '../../customHooks/executive';
import { useHasPermission } from '../../customHooks/usePermissions';

const columns = [
  { label: 'CLIENTES', key: 'cantidad_Clientes', defaultValue: 0 },
  { label: 'ACTIVOS', key: 'activados', defaultValue: 0 },
  { label: 'POR CONFIRMAR', key: 'porconfirmar', defaultValue: 0 },
  { label: 'PROYECTO', key: 'nombre_Proyecto', defaultValue: '' },
  { label: 'ETAPA', key: 'nombre_etapa', defaultValue: '' },
  { key: 'id_Proyecto', defaultValue: '', id: true },
];

const EnterpriseHome = ({
  history,
  location: { pathname },
  match: {
    params: { subsidiary },
  },
  notificationList,
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);

  const checkMessages = () => {
    history.push(`/${subsidiary}/Home/messages`);
  };

  const {
    state: { executiveRut },
  } = useContext(EnterpriseContext);

  const {
    projectList,
    activeSubsidiary,
    setActiveSubsidiary,
    addMoreRows,
    subsidiaryFilter,
  } = useGetExecutiveProjects(executiveRut);
  const permissions = useHasPermission('intranet', null);

  return (
    <div className="EnterpriseHome__container">
      <h2 className="EnterpriseHome__container__title">Buenos Días</h2>
      {/* {!!permissions['Seleccione un ejecutivo'] && ( */}
        <ExecutiveSelector className="EnterpriseHome__container__executiveSelector" />
      {/* )} */}
      <span className="EnterpriseHome__container__messageLabel">Nuevos mensajes</span>
      <div className="EnterpriseHome__container__messages">
        <NotificationList
          subsidiary={subsidiary}
          notificationList={notificationList}
          onClick={checkMessages}
        />
      </div>
      <h5 className="EnterpriseHome__container__subtitle">RESUMEN DE PROYECTOS</h5>
      <ProjectTable
        className="EnterpriseHome__container__table"
        columns={columns}
        activeSubsidiary={activeSubsidiary}
        subsidiaryOptions={subsidiaryFilter}
        onChangeSubsidiary={setActiveSubsidiary}
        itemList={projectList}
        onItemClick={projectId => {
          history.push(`${pathname}/${projectId}`);
        }}
        addMoreRowsCallback={addMoreRows}
        addMoreRowsLabel="Cargar más proyectos"
      />
    </div>
  );
};

EnterpriseHome.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      path: PropTypes.string,
      subsidiary: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
  history: PropTypes.object,
  notificationList: PropTypes.object,
};

export default withRouter(memo(EnterpriseHome));
