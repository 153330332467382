import React, { useEffect, useState, useCallback, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DownArrowIconComponent from '../../components/Icons/DownArrow';
import * as R from 'ramda';

// Components
import Grid from '@material-ui/core/Grid';
import { Checkbox, Typography } from '@material-ui/core';
import ProjectTable from '../../components/ProjectTable';
import WorkProgress from '../../components/WorkProgress';
import MultipleUpload from '../../components/MultipleUpload';
import ImageUpload from '../../components/ImageUpload';

import { EnterpriseContext } from '../../providers/EnterpriseProvider';

import {
  getClientsByProject,
  getProjectById,
  getMilestonesByProject,
  uploadWholeProject,
  getMassiveListDocsByProject,
  deleteDocMassiveByProject,
} from '../../services/projectsService';

import { uploadProjectImageBySize, deleteImage } from '../../services/fileService';

import './ProjectDetail.scss';
import { useIslogged } from '../../customHooks/useIslogged';
import { GlobalContext } from '../../providers/GlobalStateProvider';
import { useHandlePagination } from '../../hooks/clientHooks';
import { id } from 'date-fns/locale';
import { useProjectDocuments } from '../../customHooks/documents';
import {
  deleteProjectDocument,
  listClientDocuments,
  listProjectDocuments,
  uploadFileClient,
  uploadFileProject,
} from '../../services/documents';
import MilestonCalendarProject from '../../components/MilestonesCalendar/MilestonCalendarProject';

const columnas = [
  { label: 'Nombre', key: 'fullName', defaultValue: 0, ordered: false, keyOrder: 'apellidos' },
  {
    label: 'Propiedad(es)',
    key: 'propertyNumber',
    defaultValue: '901',
    ordered: true,
    keyOrder: 'propertyNumber',
  },
  { label: 'ACTIVAR / DESACTIVAR', key: 'estado_sistema', defaultValue: 5 },
  { key: 'id_Cliente', defaultValue: '', id: true },
];

const updateClientList = (idProject, page, limit, letterFilter, searchFilter, setClientList) => {
  getClientsByProject(
    idProject,
    page,
    limit,
    letterFilter,
    searchFilter,
    ({ data: { data } }) => {
      const formatData = data.map(({ propiedades, nombres, apellidos, ...rest }) => {
        let deptos = [];
        propiedades.slice(0, 2).forEach(prop => {
          deptos.push(prop.numero_Departamento);
        });
        if (propiedades.length > 2) {
          //deptos.push('(+ ' + (propiedades.length-2) + ')');
          //deptos.push('...');
          deptos.push('...(' + (propiedades.length - 2) + '+)');
        }
        const result = {
          fullName: `${nombres} ${apellidos}`,
          nombres,
          apellidos,
          //propertyNumber: propiedades[0].numero_Departamento,
          propertyNumber: deptos.join(', '),
          properties: [...propiedades],
          ...rest,
        };
        return result;
      });
      setClientList([...formatData]);
    },
    err => {
      console.error(err);
    },
  );
};

const updateDocumentList = (id_proyecto, setDocumentList) => {
  getMassiveListDocsByProject(
    id_proyecto,
    ({ data }) => {
      setDocumentList(data);
    },
    err => {
      console.error(err);
    },
  );
};

const useDocumentList = id_proyecto => {
  const [documentList, setDocumentList] = useState([]);
  useEffect(() => {
    updateDocumentList(id_proyecto, setDocumentList);
  }, []);
  return { documentList, setDocumentList };
};

const updateDocsList = (idProyecto, updateCallback) => {
  if (idProyecto) {
    listProjectDocuments(
      idProyecto,
      undefined,
      undefined,
      undefined,
      ({ data }) => {
        updateCallback(data);
      },
      err => {
        console.error(err);
      },
    );
  }
};

const ProjectDetail = ({
  match: {
    params: { idProject, subsidiary },
  },
  history,
  location: { pathname },
}) => {
  if (!useIslogged()) history.push(`/${subsidiary}`);

  const { documentList, setDocumentList } = useDocumentList(idProject);
  const { projectDocuments, setProjectDocuments } = useProjectDocuments(idProject);
  const {
    state: { executiveRut },
  } = useContext(EnterpriseContext);

  const deleteDocument = docId => {
    // deleteDocMassiveByProject(
    //   docId,
    //   () => {
    //     updateDocumentList(idProject, setDocumentList);
    //   },
    //   err => {
    //     console.error(err);
    //   },
    // );
    deleteProjectDocument(
      docId,
      () => {
        updateDocsList(idProject, data => {
          setProjectDocuments(data);
        });
      },
      err => {
        console.error(err);
      },
    );
  };

  const massiveUpload = (name, file, to, clients = []) => {
    if (clients.length > 0) {
      uploadFileProject(idProject, executiveRut, clients, to, name, file, () => {
        updateDocsList(idProject, data => {
          setProjectDocuments(data);
        });
      });
    } else {
      uploadFileProject(idProject, executiveRut, undefined, to, name, file, () => {
        updateDocsList(idProject, data => {
          setProjectDocuments(data);
        });
      });
    }
  };
  const useMilestones = () => {
    const [milestones, setMilestones] = useState({});
    useEffect(() => {
      getMilestonesByProject(
        idProject,
        ({ data: { data } }) => {
          setMilestones(data[0]);
        },
        err => {
          console.error(err);
        },
      );
    }, [idProject]);
    return { milestones, setMilestones };
  };

  const updateProjectInfo = (setProject, setImageList) => {
    getProjectById(
      idProject,
      ({ data: { data } }) => {
        setProject({ ...data[0] });
        setImageList([...data[0].imagenes]);
      },
      err => {
        console.error(err);
      },
    );
  };

  const useProjectInfo = () => {
    const [project, setProject] = useState({});
    const [imageList, setImageList] = useState([]);
    const updateProjectInfoCallback = () => updateProjectInfo(setProject, setImageList);
    useEffect(() => {
      updateProjectInfo(setProject, setImageList);
    }, []);
    return { project, imageList, updateProjectInfoCallback };
  };

  // pagination
  const { pagination, addMoreRows } = useHandlePagination();

  const useClientList = () => {
    const [clientList, setClientList] = useState([]);
    const [letterFilter, setLetterFilter] = useState('');
    const [searchFilter, setSearchFilter] = useState('*');
    const { page, limit } = pagination;
    const changeSearch = newValue => {
      if (newValue === '') newValue = '*';
      setSearchFilter(newValue);
    };
    useEffect(() => {
      updateClientList(idProject, page, limit, letterFilter, searchFilter, setClientList);
    }, [pagination, letterFilter, searchFilter]);
    return { clientList, setClientList, letterFilter, setLetterFilter, changeSearch, searchFilter };
  };

  const {
    clientList,
    setClientList,
    letterFilter,
    setLetterFilter,
    changeSearch,
    searchFilter,
  } = useClientList();
  const { project, imageList, updateProjectInfoCallback } = useProjectInfo();
  const { milestones } = useMilestones();
  const { selectClient } = useContext(EnterpriseContext);

  // Images
  const uploadProjectImageBySizeCallback = ({ files }, label) => {
    uploadProjectImageBySize(
      idProject,
      label,
      files,
      () => {
        updateProjectInfoCallback();
      },
      err => {
        console.error(err);
      },
    );
  };

  const deleteImageCallback = imageName => {
    deleteImage(
      imageName,
      () => {
        updateProjectInfoCallback();
      },
      err => {
        console.error(err);
      },
    );
  };

  const selectClientCallback = useCallback(
    client => {
      const selectedClient = clientList.find(({ id_Cliente: ClientId }) => ClientId === client);
      selectClient({ ...selectedClient, idProject });
      history.push(`${pathname}/${selectedClient.id_Cliente}`);
    },
    [clientList, selectClient, idProject],
  );
  const onClientStatusChange = () => {
    const { page, limit } = pagination;
    updateClientList(idProject, page, limit, letterFilter, searchFilter, setClientList);
  };

  const [expanded, setExpanded] = useState(true);

  const [clientListOrdered, setClientListOrdered] = useState([]);
  const orderClientList = (keyOrder, list = []) => {
    let newKeyOrder = `New${keyOrder}`;
    const clientListForOrder = list.map(client => {
      const newClient = client;
      if (typeof client[keyOrder] === 'string') {
        if (keyOrder === 'fullName') {
          newKeyOrder = `NewApellidos`;
          newClient[newKeyOrder] = client['apellidos'].toLowerCase().trim();
        } else {
          newClient[newKeyOrder] =
            client[keyOrder].indexOf(',') > -1
              ? parseInt(client[keyOrder].split(',')[0])
              : parseInt(client[keyOrder]);
        }
      }
      return newClient;
    });
    return R.sortBy(R.prop(newKeyOrder), clientListForOrder);
  };
  const onChangeOrder = order => {
    setColumns(
      columns.map(column => {
        if (column.ordered !== undefined) {
          column.ordered = false;
        }
        if (column.keyOrder === order) {
          column.ordered = true;
        }
        return column;
      }),
    );
  };
  const [onlyActive, setOnlyActive] = useState(false);
  const [keyOrder, setKeyOrder] = useState('');
  useEffect(() => {
    if (onlyActive) {
      setClientListOrdered(orderClientList(keyOrder, clientListOrdered));
    } else {
      setClientListOrdered(orderClientList(keyOrder, clientList));
    }
  }, [keyOrder, clientList]);
  const [columns, setColumns] = useState(columnas);
  useEffect(() => {
    const keysOrdered = columns.filter(column => column.ordered).map(column => column.key);
    if (keysOrdered.length > 0) {
      setKeyOrder(keysOrdered[0]);
    }
  }, [columns]);
  useEffect(() => {
    if (onlyActive) {
      setClientListOrdered(
        orderClientList(
          keyOrder,
          clientListOrdered.filter(client => client.estado_sistema !== 'desactivado'),
        ),
      );
    } else {
      setClientListOrdered(orderClientList(keyOrder, clientList));
    }
  }, [onlyActive]);

  return (
    <div className="ProjectDetail">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {project && (
            <Typography variant="h2" color="textPrimary" className="ProjectDetail__projectName">
              {project.nombre_Proyecto}
            </Typography>
          )}
          <ExpansionPanel
            key={project.id_Proyecto}
            square
            className="ProjectDetail__expansionPanel"
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <ExpansionPanelSummary
              expandIcon={<DownArrowIconComponent />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div className="ProjectDetail__expansionPanelSummary">
                <div className="ProjectDetail__expansionPanelSummary__clientName">CLIENTES</div>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className="ProjectDetail__expansionPanelSummary__check">
                <Checkbox
                  checked={onlyActive}
                  onChange={() => setOnlyActive(!onlyActive)}
                  color="primary"
                />
                <span>Solo Activos</span>
              </div>
              <ProjectTable
                columns={columns}
                itemList={clientListOrdered}
                subsidiary={subsidiary}
                onItemClick={selectClientCallback}
                onLetterChangeCallback={setLetterFilter}
                onChangeSearch={changeSearch}
                onClientStatusChange={onClientStatusChange}
                addMoreRowsLabel="Cargar más clientes"
                addMoreRowsCallback={addMoreRows}
                messageEmpty="No existen clientes para la filial seleccionada"
                onChangeOrder={onChangeOrder}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <h5 className="ProjectDetail__title">PUBLICAR IMAGENES DE PROYECTOS</h5>
          <ImageUpload
            onSubmit={uploadProjectImageBySizeCallback}
            imagesList={imageList}
            deleteImageCallback={deleteImageCallback}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h5 className="ProjectDetail__title">HITOS DEL PROYECTO</h5>
          <MilestonCalendarProject
            subsidiary={subsidiary}
            idProject={idProject}
            projectName={project.nombre_Proyecto}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h5 className="ProjectDetail__title">SUBIR DOCUMENTO</h5>
          <MultipleUpload
            subsidiary={subsidiary}
            idProject={idProject}
            projectName={project.nombre_Proyecto}
            onSubmit={massiveUpload}
            documentList={documentList}
            deleteCallback={deleteDocument}
            listDocuments={projectDocuments}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <h5 className="ProjectDetail__title">AVANCE DE OBRA</h5>
          <WorkProgress subsidiary={subsidiary} milestones={milestones} />
        </Grid>
      </Grid>
    </div>
  );
};

ProjectDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      idProject: PropTypes.string,
      subsidiary: PropTypes.string,
    }),
  }),
  history: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default memo(ProjectDetail);
