/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import ProjectItem from '../ProjectItem';
import SwitchButton from '../SwitchButton';

import { gallery, list } from '../../constants/filialesNames';

import './ProjectList.scss';

const useSelect = () => {
  const [listOrganization, setListOrganization] = useState(gallery);

  const selectGallery = () => {
    setListOrganization(gallery);
  };

  const selectList = () => {
    setListOrganization(list);
  };
  return { listOrganization, selectGallery, selectList };
};

const   ProjectList = ({ subsidiary, projects = [], selectProperty }) => {
  const { listOrganization, selectGallery, selectList } = useSelect();
  const subsidiaryProjects = projects.filter(project => project.filial === subsidiary);
  const otherProjects = projects.filter(project => project.filial !== subsidiary);
  return (
    <div className="ProjectList">
      <div className="ProjectList__switch">
        {projects.length > 1 ? (
          <SwitchButton
            subsidiary={subsidiary}
            select={listOrganization}
            selectGallery={selectGallery}
            selectList={selectList}
          />
        ) : (
          <div />
        )}
      </div>
      <div className="ProjectList__list">
        {subsidiaryProjects.map((project, index) => (
          <ProjectItem
            subsidiary={subsidiary}
            key={index}
            listOrganization={listOrganization}
            onClick={selectProperty}
            {...project}
          />
        ))}
        {otherProjects.length > 0 && (
          <Accordion className="ProjectList__otherProjectsAccordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3 className={`ProjectList__subsidiaryNames--${subsidiary}`}>
                MIS PROPIEDADES DE OTRAS FILIALES DE EMPRESAS SOCOVESA
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="ProjectList__others">
                {otherProjects.map((project, index) => (
                  <ProjectItem
                    subsidiary={subsidiary}
                    key={index}
                    listOrganization={listOrganization}
                    onClick={selectProperty}
                    {...project}
                  />
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};

ProjectList.propTypes = {
  subsidiary: PropTypes.string,
  projects: PropTypes.array,
  selectProperty: PropTypes.func,
};

export default ProjectList;
