import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../ButtonTypes';

import { empresasPrimary, empresasSecondary } from '../../constants/colors';

import { sendActivationEmail } from '../../services/clientService';

import './ClientStatus.scss';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { deleteClient } from '../../services/postventaService';
import { useHasPermission } from '../../customHooks/usePermissions';
import { useHistory } from 'react-router-dom';

const clientStates = {
  desactivado: 'desactivado',
  pendiente: 'pendiente',
  activado: 'activado',
};

const getStateButtonText = {
  [clientStates.desactivado]: 'activar cliente',
  [clientStates.activado]: 'cliente activo',
  //[clientStates.pendiente]: 'VOLVER A INTENTAR',
  [clientStates.pendiente]: 'POR CONFIRMAR',
};

const activateClient = (clientId, onClientStatusChange, openDialog, restore = false) => {
  sendActivationEmail(
    clientId,
    restore,
    () => {
      if (onClientStatusChange) {
        onClientStatusChange();
        openDialog();
      }
    },
    err => {
      console.error(err);
    },
  );
};

const ClientStatus = ({
  clientStatus,
  clientId,
  clientMongoId,
  onClientStatusChange,
  disabled = false,
  email,
  cliente,
  history,
  propertiesStates=null,
}) => {
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const thisHistory = useHistory();
  const getCallBack = {
    [clientStates.desactivado]: () =>
      activateClient(clientId, onClientStatusChange, () => {
        setReset(false);
        setOpen(true);
      }),
    [clientStates.activado]: undefined,
    [clientStates.pendiente]: () =>
      activateClient(clientId, onClientStatusChange, () => {
        setReset(false);
        setOpen(true);
      }),
  };
  const resetClient = () => {
    deleteClient(
      clientMongoId,
      () => {
        activateClient(
          clientId,
          onClientStatusChange,
          () => {
            setReset(true);
            setOpen(true);
          },
          true,
        );
      },
      err => {
        console.error({ err });
      },
    );
  };
  const permissions = useHasPermission(sessionStorage.getItem('filial'), propertiesStates);
  return clientStatus !== '' && getStateButtonText[clientStatus] ? (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        reset={reset}
      >
        <DialogTitle id="alert-dialog-title">{'Notificación de activación'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {reset ? (
              <p>
                Se restablecido la contraseña y se ha enviado una notificación a{' '}
                <strong>{cliente}</strong> al correo <strong>{email}</strong> para que active su
                cuenta.
              </p>
            ) : (
              <p>
                Se ha enviado una notificación a <strong>{cliente}</strong> al correo{' '}
                <strong>{email}</strong> para que active su cuenta.
              </p>
            )}
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Aceptar
            </Button>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <div className="button-container">
        <Button
          disabled={
            // clientStatus === clientStates.activado || disabled || !permissions['Activar Cliente']
            clientStatus === clientStates.activado || disabled
          }
          onClick={getCallBack[clientStatus]}
          style={{
            width: '15rem',
            background:
              clientStatus === clientStates.pendiente ? empresasPrimary : "#0093ca",
          }}
        >
          {getStateButtonText[clientStatus]}
        </Button>
        {!!clientMongoId && (
          <Button
            // disabled={!clientMongoId || clientStates.desactivado === clientStatus || !permissions['Restablecer Contraseña']}
            disabled={!clientMongoId || clientStates.desactivado === clientStatus}
            onClick={_e => resetClient()}
            style={{
              width: '15rem',
              background: empresasPrimary,
            }}
          >
            Restablecer contraseña
          </Button>
        )}
        <Button
          onClick={e => thisHistory.push(`/empresas/Home/clients/${clientId}/additional`)}
          // disabled={!permissions['Administrar Adicionales']}
          style={{
            width: '15rem',
            background: empresasPrimary,
          }}
        >
          Adicionales
        </Button>
      </div>
    </>
  ) : null;
};

ClientStatus.propTypes = {
  clientStatus: PropTypes.string,
  clientId: PropTypes.string,
  clientMongoId: PropTypes.string,
  onClientStatusChange: PropTypes.func,
  disabled: PropTypes.bool,
  email: PropTypes.string,
  cliente: PropTypes.string,
};

export default ClientStatus;
