import React from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { currentColor } from '../../../constants/colors';

const Download = ({ color = currentColor, className, alt }) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={className} style={{marginRight: '10px'}}>
    <title>{alt}</title>
    <g transform="translate(0.000000,24.000000) scale(0.004687,-0.004687)"
    fill={color} stroke="none">
        <path d="M988 5096 c-192 -68 -349 -227 -420 -426 l-23 -65 0 -2035 0 -2035
        23 -65 c72 -201 231 -360 432 -432 l65 -23 1479 -3 c1651 -3 1527 -8 1686 69
        69 34 101 58 170 128 89 89 130 154 172 271 l23 65 3 1585 c2 1532 2 1586 -17
        1615 -10 17 -307 333 -660 703 l-642 672 -1112 0 -1112 0 -67 -24z m2094 -658
        c3 -365 7 -451 19 -488 64 -186 190 -313 369 -372 61 -20 90 -22 468 -26 l402
        -4 0 -1467 c0 -1465 0 -1467 -21 -1529 -50 -147 -180 -257 -336 -282 -78 -13
        -2748 -13 -2827 0 -126 20 -253 108 -307 211 -57 108 -53 -40 -57 2044 -1
        1214 1 1943 7 1983 28 182 172 332 348 362 26 4 472 8 990 9 l942 1 3 -442z
        m649 -171 c211 -222 395 -416 409 -431 l25 -27 -300 3 -300 3 -57 28 c-71 35
        -120 88 -147 161 -20 53 -21 75 -21 361 0 168 2 305 4 305 2 0 177 -181 387
        -403z"/>
        <path d="M2503 3410 c-60 -36 -58 -5 -63 -752 l-5 -686 -250 270 c-137 148
        -262 276 -277 284 -36 19 -108 18 -136 -2 -47 -33 -67 -121 -39 -170 23 -41
        756 -820 782 -832 37 -17 75 -15 112 6 18 9 201 198 407 420 405 436 402 430
        377 513 -8 27 -23 46 -44 59 -34 21 -106 27 -136 10 -10 -5 -133 -132 -272
        -282 l-254 -272 -5 685 c-5 651 -6 685 -24 712 -38 57 -114 73 -173 37z"/>
        <path d="M1516 1110 c-50 -16 -96 -73 -96 -120 0 -37 32 -91 64 -111 30 -18
        71 -19 1085 -19 1030 0 1054 0 1085 20 77 47 81 152 9 207 l-36 28 -1041 2
        c-573 1 -1054 -2 -1070 -7z"/>
    </g>
  </svg>
);

Download.propTypes = {
  theme: PropTypes.object,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles(
  {}, 
  { withTheme: true })(Download);
